export const environment = {
  production: true,
  currentEnvironment: "Development",
  value: "https://qinsight-dev.myqone.com/value/#/value/alliancesummaryanalytics",
  Login_url:
    "https://accounts-dev.myqone.com/#/login?continue=https://qinsight-dev.myqone.com/puthash/setSID",
  Logout_url:
    "https://accounts-dev.myqone.com/#/logout?continue=https://accounts-dev.myqone.com/puthash/setSID",
  maintainancelogout: "https://accounts-dev.myqone.com/#/logout?continue",
  ValueCall_url: "https://qinsightvalue-dev.myqone.com/restservice/",
  AuthorizeUser_url:
    "https://qore-dev.myqone.com/auth/cicp/authorizeUserForApp/",
  Roleid_url: "https://qore-dev.myqone.com/auth/users/applicationpermissions/",
  RestService_url: "https://qinsight-dev.myqone.com/restservice/",
  RestService_miu: "https://qinsight-dev.myqone.com/restservicemiu/",
  RestService_rcm: "https://qinsight-dev.myqone.com/restservicercm/",
  RestService_value: "https://qinsight-dev.myqone.com/restservicevalue/",
  RestService_trend: "https://qinsight-dev.myqone.com/restservicetrend/",
  RestService_Financial: "https://qinsight-dev.myqone.com/restservicefmetrics/",
  RestService_hub: "https://qinsight-dev.myqone.com/restservicehub/",
  RestService_ML: "https://qinsight-dev.myqone.com/mlrestservice/",
  document_url: "https://qinsight-dev.myqone.com/",
  getUserList: "https://qore-dev.myqone.com/auth/users/5/0",
  Auth_url: "https://qore-dev.myqone.com/auth/",
  QonductorSaveData: "https://qonductor-dev.myqone.com/QConstrue/ClaimAction/",
  getmyqoneUser: "https://qore-dev.myqone.com/auth/users/2/0",
  gotomyqone: "https://dev.myqone.com",
  AuditLog: "https://qore-dev.myqone.com/audit/v1/WriteAuditLog",
  PGroupURL: "https://qore-dev.myqone.com/auth/groups/getgroupsbyuserid",
  CGroupURL: "https://qore-dev.myqone.com/auth/groups/byparentid/",
  MyQone_GroupURL: "https://qore-dev.myqone.com/auth/groups/",
  MRIServiceURL: "https://qmriexport-dev.myqone.com/restservice/",
  HonestServiceURL: "https://banq-dev.myqone.com/editranslator/api/Reports/",
  UserByroleID: "https://qore-dev.myqone.com/auth/users/getUserByRole/344",
  GetToken_UsingEMRMappingId: "https://qore-dev.myqone.com/auth/users/authenticateUser/",
  auditUrl: "https://qore-dev.myqone.com/",

  firebase: {
    apiKey: "AIzaSyCKHy-uhhXbITqXSteKv7lAKdAVbFwpPMY",
    authDomain: "qpathways-dev.firebaseapp.com",
    databaseURL: "https://qpathways-dev.firebaseio.com",
    projectId: "qpathways-dev",
    storageBucket: "qpathways-dev.appspot.com",
    messagingSenderId: "413178660353"
  },
};
